import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function navbar() {
  return (
    // <Navbar expand="xs">
      <Container className='align-center'>
        <div className='header-btn'>
        <img src="/carnosine-new.png"></img>
          {/* <div className=" btn-mission">
           <img className='grow-svg' src='grow.svg'></img> <h3>Mission <br></br>Carnosine</h3>
           
          </div> */}
          
        </div>
        {/* <Navbar.Brand href="/"><img src='/logo-brand-1.png'></img></Navbar.Brand> */}
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        {/* <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link href="#home">Our Products</Nav.Link>
    <Nav.Link href="#features">Gift Bundles</Nav.Link>
             <Nav.Link href="#pricing">What's New</Nav.Link>
           <Nav.Link href="#roll">Articles</Nav.Link>
           
           
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    // </Navbar>
  );
}

export default navbar;