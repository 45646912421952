import { React, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { postalCode, CampaignRegister } from "../../Services/Services";
import ClipLoader from "react-spinners/ClipLoader";
import { useData } from "../../utils/dataContext";
import { useEffect } from "react";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';


export default function QuizLoginDirect(props) {
    const quizDetails = useSelector(state => state.quizDetails)
    // const quizAnswers = useSelector(state => state.quizDetails)
    const { updateScreen, currentScreen, setIsRegistered } = useData();

    const navigate = useNavigate();
    const { register, handleSubmit, watch, setValue, setError, formState: { errors } } = useForm();
    const allFormValues = watch();
    const [pinCode, setPinCode] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAddress, setIsLoadingAddress] = useState(false);
    const [addressOptions, setAddressOptions] = useState();
    const [isPolicyChecked, setIsPolicyChecked] = useState(false);
    const [dob, setDob] = useState();

    useEffect(() => {
        if (currentScreen == 'start-quiz') {
            navigate('/start-quiz')
        } else {
            updateScreen('quiz-login');
        }
    }, [])

    const handlePolicyChange = () => {
        setIsPolicyChecked(!isPolicyChecked);
    }

    const handlePincode = (e) => {
        if (e.target.value?.length <= 5) {
            setPinCode(e.target.value);
            if (e.target.value?.length == 5) {
                setIsLoadingAddress(true)
                postalCode(e.target.value)
                    .then((res) => {
                        setIsLoadingAddress(false)
                        setAddressOptions(res.data);
                    })
                    .catch((err) => {
                        setIsLoadingAddress(false)
                        console.error(err);
                    });
            } else {
                setAddressOptions([]);
            }
        }
    };

    const onSubmit = (data) => {

        let finalObject = {
            campaignid: 3,
            userid: 0,
            name: data.name,
            mobile: data.mobile,
            email: data.email,
            dob: data.dob,
            orderid: '',
            receipt: '',
            ecommerceid: 0,
            engrave: '',
            mobileAlt: '',
            address1: data.address1,
            address2: data.address2,
            postalcode: data.postalcode,
            // campaignqns: quizDetails.quizAnswers
            campaignqns: props.quizData.quizAnswers ?? {}
        }

        setIsLoading(true)
        CampaignRegister('', JSON.stringify(finalObject))
            .then((res) => {
                if (res?.data == 'You have successfully registered for the campaign.') {
                    setIsRegistered(true);
                    navigate('/quiz-share')
                }
                setIsLoading(false)

            }).catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    return (
        <div>
            {
                isLoading ? <div className="loader-container">
                    <div className="loader-wrapper">
                        <ClipLoader
                            loading={true}
                            color="white"
                            size={50}
                            aria-label="Loading Spinner"
                        />
                    </div>
                </div> :
                    <Container>
                        <Container className="mt-2" style={{
                            backgroundImage: "url('login-cover-template.png')",
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat',
                            width: '90%'
                        }}>
                            <Row className="justify-content-center">
                                <Col xs={12} className="mt-3">
                                    <label style={{ color: 'palegoldenrod', display: 'flex', justifyContent: 'center', fontSize: '30px' }}>Login</label>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-3">
                                <Col xs={12}>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group controlId="name" 
                                            style={{
                                                backgroundImage: "url('login-background.png')",
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                            <Form.Control
                                                type="text"
                                                placeholder="ชื่อ นามสกุล"
                                                style={{ background: 'transparent', border: 'none', color: 'white' }}
                                                {...register('name', { required: 'จำเป็นต้องกรอกข้อมูล' })}
                                            />

                                        </Form.Group>
                                        {errors.name && <span style={{ fontSize: '10px !important', color: 'red' }}>{errors.name.message}</span>}

                                        <Form.Group controlId="email" className="mt-3"
                                            style={{
                                                backgroundImage: "url('login-background.png')",
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                            <Form.Control
                                                type="email"
                                                placeholder="อีเมลล์"
                                                style={{ background: 'transparent', border: 'none', color: 'white' }}
                                                {...register('email', {
                                                    required: 'จำเป็นต้องกรอกข้อมูล',
                                                    pattern: {
                                                        value: /^\S+@\S+\.\S+$/,
                                                        message: 'Invalid email Id'
                                                    }
                                                })}
                                            />
                                        </Form.Group>
                                        {errors.email && <span style={{ fontSize: '10px !important', color: 'red' }}>{errors.email.message}</span>}

                                        <Form.Group controlId="mobile" className="mt-3"
                                            style={{
                                                backgroundImage: "url('login-background.png')",
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                            <Form.Control
                                                type="text"
                                                placeholder="หมายเลขโทรศัพท์"
                                                style={{ background: 'transparent', border: 'none', color: 'white' }}
                                                {...register('mobile', {
                                                    required: 'จำเป็นต้องกรอกข้อมูล',
                                                    pattern: {
                                                        value: /^0[6-9]\d{8}$/,
                                                        message: 'Invalid mobile number',
                                                    },
                                                })}
                                            />
                                        </Form.Group>
                                        {errors.mobile && <span style={{ fontSize: '10px !important', color: 'red' }}>{errors.mobile.message}</span>}

                                        <Form.Group controlId="dob" className="mt-3"
                                            style={{
                                                backgroundImage: "url('login-background.png')",
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat',
                                                height: '56px'
                                            }}>
                                            <DatePicker
                                                {...register('dob', {
                                                    required: 'จำเป็นต้องกรอกข้อมูล'
                                                })
                                                }
                                                placeholder="วันเดือนปีเกิด"
                                                name="dob"
                                                locale={locale}
                                                format="DD/MM/YYYY"
                                                value={dob}
                                                disabledDate={(current) => {
                                                    const maxSelectableDate = dayjs();
                                                    return current && current > maxSelectableDate.endOf('day');
                                                }}
                                                onChange={(date, dateString) => {
                                                    let minDate = dayjs().subtract(6, 'years');
                                                    let selectedDate = dayjs(dateString, 'DD/MM/YYYY');
                                                    if (selectedDate.isAfter(minDate)) {
                                                        setError('dob', {
                                                            type: "manual",
                                                            message: "User should be atleast 6 years old",
                                                        });
                                                    } else {
                                                        setError('dob', '');
                                                        setValue('dob', dateString);
                                                        setDob(date);
                                                    }

                                                }}
                                                allowClear={false}
                                                showToday={false}
                                            />
                                        </Form.Group>
                                        {errors.dob && <span style={{ fontSize: '10px !important', color: 'red' }}>{errors.dob.message}</span>}


                                        <Form.Group controlId="postalcode" className="mt-3"
                                            style={{
                                                backgroundImage: "url('login-background.png')",
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                            {/* /^[0-9]+$/ */}
                                            <Form.Control
                                                type="tel"
                                                maxLength="5"
                                                placeholder={'รหัสไปรษณีย์'}
                                                name="postalcode"
                                                value={pinCode}
                                                style={{ background: 'transparent', border: 'none', color: 'white' }}
                                                {...register('postalcode', {
                                                    required: 'จำเป็นต้องกรอกข้อมูล',
                                                    pattern: {
                                                        value: /^\d{5}$/,
                                                        message: 'Invalid zip format',
                                                    },
                                                })}
                                                onChange={(e) => {
                                                    if (e.target.value?.length < 5) {
                                                        setValue('address1', '');
                                                    }
                                                    handlePincode(e);
                                                    setError('postalcode', '')
                                                }}
                                            />
                                        </Form.Group>
                                        {errors.postalcode && <span style={{ fontSize: '10px !important', color: 'red' }}>{errors.postalcode.message}</span>}

                                        {
                                            isLoadingAddress &&
                                            <div className="loader-overlay">
                                                <ClipLoader
                                                    loading={true}
                                                    color="white"
                                                    size={50}
                                                    aria-label="Loading Spinner"
                                                />
                                            </div>
                                        }
                                        <Form.Group controlId="address1" className="mt-3"
                                            style={{
                                                backgroundImage: "url('login-background.png')",
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                            <Form.Select
                                                aria-label="Address"
                                                style={{ background: 'transparent', border: 'none', color: 'white' }}
                                                {...register('address1', { required: 'จำเป็นต้องกรอกข้อมูล' })}
                                            >
                                                <option value="">ที่อยู่ 1</option>
                                                {addressOptions && addressOptions?.map((option, index) => (
                                                    <option key={index} value={option.address2}>
                                                        {option.address2}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        {errors.address1 && <span style={{ fontSize: '10px !important', color: 'red' }}>{errors.address1.message}</span>}



                                        <Form.Group controlId="address2" className="mt-3"
                                            style={{
                                                backgroundImage: "url('login-background.png')",
                                                backgroundSize: '100% 100%',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                            <Form.Control
                                                type="text"
                                                // className="placeholder-gray"
                                                style={{ background: 'transparent', border: 'none', color: 'white' }}
                                                placeholder="ที่อยู่ 2"
                                                {...register('address2', { required: 'จำเป็นต้องกรอกข้อมูล' })}
                                            />
                                        </Form.Group>
                                        {errors.address2 && <span style={{ fontSize: '10px !important', color: 'red' }}>{errors.address2.message}</span>}

                                        <div className='d-flex justify-content-center align-item-center mt-2'>
                                            <Form.Check type="checkbox" id="checkboxId" checked={isPolicyChecked}>
                                                <Form.Check.Input type="checkbox" onChange={handlePolicyChange} />
                                                <Form.Check.Label style={{ color: 'white' }}>
                                                    ท่านได้อ่านและทำความเข้าใจ<a target='blank' href="https://www.brandsworld.co.th/privacy-policy.html" style={{ textDecoration: 'underline', color: 'white', cursor: "pointer" }}>
                                                        นโยบายความเป็นส่วนตัว</a>
                                                    ของบริษัทแล้ว และยินยอมให้บริษัทเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อการแจ้งข้อมูลข่าวสารและนำเสนอผลิตภัณฑ์ของบริษัท
                                                </Form.Check.Label>
                                            </Form.Check>

                                        </div>
                                        <div className='d-flex justify-content-center align-item-center pad-tb-10'> <Button
                                            type="submit"
                                            disabled={!isPolicyChecked}
                                            style={{ backgroundImage: "url('btn-clip-full.svg')" }} className='start-btn'>
                                            {/* Continue */}ต่อไป
                                        </Button></div>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>


                    </Container>
            }

            {/* {
                isPolicyChecked && <Modal show={isPolicyChecked} onHide={handleModalClose} style={{ paddingTop: '30px' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Privacy Policy</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '900px', overflow: 'auto', padding: '1px' }}>
                        <Card.Text>
                            <div className="tc-quiz-popup">
                                <ol type="a">
                                    <li> บริษัท ซันโทรี่ เบเวอเรจ แอนด์ ฟู้ด (ประเทศไทย) จำกัด (“บริษัท”) ได้จัดกิจกรรม “ท้าให้ลอง ติดต่อกัน” ระหว่างวันที่ 1 พฤศจิกายน 2566 – 31 ธันวาคม 2566 </li>
                                    <li> ผู้สนใจสามารถลงทะเบียนเพื่อรับสินค้าทดลองฟรี (จำกัด 1 คนต่อ 1 สิทธิ์ จำนวนทั้งสิ้น 1,000 สิทธิ์เท่านั้น) โดยการสแกนคิวอาร์โค้ดที่กล่องผลิตภัณฑ์แบรนด์ซุปไก่สกัด หรือกด Banner ที่ปรากฏในเว็บไซต์ www.pobpad.com หรือ ลงทะเบียนที่เว็บไซต์ <a className="text-primary text-decoration-underline" target='blank' href="https://sampling.brandsworld.co.th/">https://sampling.brandsworld.co.th/</a> และกรอกข้อมูลส่วนตัว ได้แก่ ชื่อ-นามสกุล (ตามบัตรประชาชน) หมายเลขโทรศัพท์ อีเมล วันเดือนปีเกิด และที่อยู่ รวมถึงตอบคำถามตามที่กำหนด</li>
                                    <li> หากผู้ลงทะเบียนรับสินค้าทดลองสนใจรับของรางวัลเพิ่มเติม สามารถร่วมกิจกรรมเพิ่มเติมได้ ดังต่อไปนี้ </li>
                                    <ol type="i">
                                        <li> รางวัลต่อที่ 1 เมื่อผู้ร่วมกิจกรรมได้เข้ามาเขียนแสดงความคิดเห็นเกี่ยวกับผลิตภัณฑ์แบรนด์  (Review) หลังจากได้รับสินค้าทดลอง โดยบรรยายถึงความรู้สึกหลังจากที่ได้ทดลองรับประทานแบรนด์ซุปไก่สกัดติดต่อกัน ผ่านทาง <a className="text-primary text-decoration-underline" target='blank' href="https://www.brandsreward.com/BECreview">https://www.brandsreward.com/BECreview</a> ผู้ร่วมกิจกรรมจะได้รับบัตรกำนัลโลตัส มูลค่า 100 บาท จำนวน 3 ใบ ต่อ 1 รางวัล ต่อ 1 รายชื่อ (จำกัดจำนวน 1,000 รางวัลเท่านั้น) โดยบริษัทจะจัดส่งของรางวัลให้ตามที่อยู่ที่ผู้ร่วมกิจกรรมได้ลงทะเบียนไว้</li>
                                        <li> รางวัลต่อที่ 2 เมื่อผู้ร่วมกิจกรรมแชร์การเขียนแสดงความคิดเห็น (Review) ของผู้ร่วมกิจกรรมบน Facebook ส่วนตัวของผู้ร่วมกิจกรรม พร้อมตั้ง caption และตั้งค่าโพสต์เป็นสาธารณะ (Public) พร้อมใส่ Hashtag #BRANDSreview การรีวิวที่มีจำนวนยอดแชร์ (Share) สูงสุดเป็นอันดับแรกในแต่ละเดือน จะได้รับรางวัล iPhone 15 Pro Max ความจุ 512 GB มูลค่า 57,900 บาท จำนวน 1 รางวัลต่อเดือน (รวมทั้งสิ้น 2 รางวัลตลอดระยะเวลาของกิจกรรม)</li>
                                        <li> บริษัทจะประกาศรายชื่อผู้ได้รับรางวัลต่อที่ 2 ประจำเดือนพฤศจิกายน ในวันที่ 18  ธันวาคม 2566 และประจำเดือนธันวาคมในวันที่ 18 มกราคม 2567 ผ่านทาง <a className="text-primary text-decoration-underline" target='blank' href="https://www.brandsreward.com/BECreview">https://www.brandsreward.com/BECreview</a> </li>
                                        <li> ในกรณีที่มีผู้ได้รับยอดแชร์สูงสุด มากกว่า 1 คน คณะกรรมการของบริษัทจะพิจารณาจาก Caption ของการรีวิวเพิ่มเติม โดยคำตัดสินของคณะกรรมการถือเป็นที่เด็ดขาดและสิ้นสุด </li>
                                        <li> ผู้ที่ได้รับรางวัลต่อที่ 2 (iPhone 15 Pro Max ความจุ 512 GB) ต้องติดต่อขอรับรางวัลผ่านทาง Inbox Facebook: BRAND’S World Thailand ภายในวันที่ 20 ธันวาคม 2566 และ 20 มกราคม 2567 ตามลำดับ มิฉะนั้น จะถือว่าสละสิทธิ์ และบริษัทจะจัดให้ผู้ได้ที่มียอดแชร์สูงสุดในลำดับถัดไปได้รับรางวัลแทน</li>
                                        <li> ผู้ที่ได้รับรางวัลต่อที่ 2 (iPhone 15 Pro Max ความจุ 512 GB) จะต้องมารับรางวัลด้วยตนเอง โดยต้องแสดงบัตรประชาชนพร้อมสำเนาเพื่อประกอบการรับรางวัล หรือ สามารถมอบอำนาจให้กับผู้รับมอบอำนาจมารับของรางวัลได้ โดยต้องนำหนังสือมอบอำนาจ ติดอากรแสตมป์ 10 บาท พร้อมแนบสำเนาบัตรประชาชนของผู้มอบอำนาจและผู้รับมอบอำนาจที่ลงนามรับรองสำเนาถูกต้องมาเพื่อประกอบการรับรางวัลด้วย</li>
                                    </ol>
                                    <li> บริษัทได้มอบหมายให้ บริษัท ลัคกี้วันกรุ๊ป จำกัด เป็นคนจัดส่งของรางวัลต่อที่ 1 (บัตรกำนัลโลตัส มูลค่า 100 บาท จำนวน 3 ใบ ต่อ 1 รางวัล) ทางไปรษณีย์ ภายใน 7-10 วัน นับจากวันที่ผู้ร่วมกิจกรรมรีวิวผล สำหรับรางวัลต่อที่ 2 (iPhone 15 Pro Max ความจุ 512 GB) ผู้ได้รับรางวัลจะต้องมารับรางวัลภายใน 30 วันนับจากวันที่ประกาศผล มิฉะนั้น จะถือว่าสละสิทธิ์ และบริษัทจะจัดให้ผู้ได้ที่มียอดแชร์สูงสุดในลำดับถัดไปได้รับรางวัลแทน</li>
                                    <li> ของรางวัลทั้งหมดไม่สามารถเปลี่ยนแปลงเป็นเงินสดหรือของอื่นใดได้ และไม่สามารถโอนสิทธิ์ให้ผู้อื่นได้</li>
                                    <li> ผู้ร่วมกิจกรรมทุกท่านยอมรับว่าได้อ่านและเข้าใจในเงื่อนไขของกิจกรรมก่อนการเข้าร่วมรายการแล้ว</li>
                                    <li> ผู้เข้าร่วมกิจกรรมรับทราบถึงวัตถุประสงค์ของบริษัทในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้เข้าร่วมกิจกรรม ซึ่งได้แก่ ชื่อ – นามสกุล ที่อยู่ หมายเลขโทรศัพท์ อีเมลล์ วันเดือนปีเกิด และข้อมูลส่วนบุคคลที่ปรากฏบนบัตรประชาชน เพื่อใช้ในตรวจสอบความถูกต้องและจัดส่งของรางวัล ทั้งนี้ การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรมจะเป็นไปเท่าที่จำเป็นตามวัตถุประสงค์ที่ได้แจ้งไว้เท่านั้น กรณีที่ผู้ร่วมกิจกรรมไม่ประสงค์ให้บริษัทจัดเก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรมดังกล่าว ย่อมส่งผลให้บริษัทไม่สามารถดำเนินการติดต่อผู้ร่วมกิจกรรมได้ และอาจทำให้ผู้ร่วมกิจกรรมเสียสิทธิการได้รับรางวัลตามกิจกรรมนี้</li>
                                    <li> ผู้ร่วมกิจกรรมยอมรับว่าได้อ่านประกาศเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของบริษัทที่ QR Code ด้านล่างแล้วก่อนเข้าร่วมกิจกรรม </li>
                                </ol>

                            </div>
                        </Card.Text>
                    </Modal.Body>

                </Modal>
            } */}
        </div>
    );
}