import React from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Button, Form, Modal, Card } from "react-bootstrap";
import { useData } from "../../utils/dataContext";
import { useSelector, useDispatch } from "react-redux";
import { quizScreen } from "../../Redux/action";
import { API } from "../../Constants/Constants";
import { useEffect, useState } from "react";

const QuizShare = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateScreen, currentScreen, isRegistered } = useData();
    const { userData, quizDetails } = useSelector(state => state);

    const [isShowTerms, setIsShowTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [scoreFileName, setScoreFileName] = useState('');
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [rankName, setRankName] = useState('');

    useEffect(() => {
        if (currentScreen == 'start-quiz' || !isRegistered) {
            navigate('/start-quiz')
        } else {
            updateScreen('quiz-share');
            dispatch(quizScreen('quiz-share'));
            let score = quizDetails.scorePercentage != undefined ? quizDetails.scorePercentage : 0;
            score = score == 0 ? 25 : score;
            let scoreName = `score-${score}.html`;
            setScoreFileName(scoreName);

            if (score == 0 || score == 25) {
                setRankName('Carnosine Researcher')
            } else if (score == 50) {
                setRankName('Carnosine Assistant')
            } else if (score == 75) {
                setRankName('Carnosine Associate')
            } else {
                setRankName('Carnosine Professor')
            }

        }
    }, [])

    const handleModalClose = () => setIsShowTerms(false);
    const handleModalShow = () => setIsShowTerms(true);

    const onChangeTerms = () => {
        setIsTermsChecked(!isTermsChecked)
    }

    // console.info(API.FACEBOOK_SHARE_URL, 'scoreFileName:', scoreFileName)

    const onClickShare = () => {

        // const shareUrl = `https://www.facebook.com/sharer.php?u=${API.FACEBOOK_SHARE_URL}${scoreFileName}&hashtag=%23carnosine`;
        // const width = 600;
        // const height = 400;
        // const left = window.innerWidth / 2 - width / 2;
        // const top = window.innerHeight / 2 - height / 2;

        // window.open(shareUrl, 'facebook-share', `width=${width},height=${height},left=${left},top=${top}`);
        // navigate('/start-quiz')

        window.FB.ui({
            display: 'popup',
            method: 'share',
            hashtag: '#carnosine',
            href: `${API.FACEBOOK_SHARE_URL}${scoreFileName}`,
        }, function (response) {
            if (response && !response.error_code) {
                console.log('Posted successfully!');
                navigate('/start-quiz');
            } else {
                console.log('Post canceled or encountered an error.');
            }
        });

    }

    return (
        <div>
            <Container className="mt-2" style={{
                backgroundImage: "url('login-cover-template.png')",
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                width: '90%'
            }}>
                <Row className="mb-2">
                    <div className='img-element-main'>
                        <img src='image-bk-start.png'></img>
                    </div>
                </Row>
                <Row className=" share-title">
                    <div className="e-share">
                        <p style={{ color: 'palegoldenrod' }}>
                            {/* You are now a */}
                            ฉันคือ
                        </p>
                        <div className="e-rank">
                            <p style={{ color: 'palegoldenrod' }}>{`${rankName != undefined && rankName != '' ? rankName : '-'}`}</p>
                        </div>
                    </div>

                </Row>
                <Row className="">
                    <div className="d-flex justify-content-center share-para">
                        <p style={{ color: 'white' }}>เขียนความเข้าใจของคุณว่า คาร์โนซีน คืออะไร <br></br> #carnosine <br></br>
                            #boostbrain</p>
                    </div>

                </Row>
            </Container>
            <Container>
                <Row>
                    <div className='d-flex justify-content-center align-item-center mb-1 mt-2'>
                        <Form.Check type="checkbox" id="checkboxId" checked={isTermsChecked}>
                            <Form.Check.Input type="checkbox" onChange={onChangeTerms} />
                            <Form.Check.Label style={{ color: 'white' }}> ฉันยอมรับ <a onClick={handleModalShow} style={{ textDecoration: 'underline', color: 'white', cursor: "pointer" }}>
                                {/* terms and conditions */} ข้อตกลงและเงื่อนไข
                            </a></Form.Check.Label>
                        </Form.Check>
                    </div>

                    <div className="d-flex justify-content-center text-center mt-2 mb-2">
                        <p style={{ color: 'white' }} className="mb-1">
                            {/* Share on Facebook & get a  */}
                            แชร์บน Facebook </p>
                    </div>

                    <div className='d-flex justify-content-center align-item-center mb-3' >

                        <Button onClick={onClickShare}
                            disabled={!isTermsChecked}
                            style={{ backgroundImage: "url('btn-clip-full.svg')" }} className='start-btn'>

                            แชร์บน Facebook
                            <img src="facebook-icon-share.png" alt="Button Icon" style={{ marginLeft: '5px' }} />
                        </Button>




                        {/* <div class="fb-share-button"
                            data-href={`${API.FACEBOOK_SHARE_URL}${scoreFileName}`}
                            data-layout=""
                            onClick={onClickShare}
                            style={{ backgroundImage: "url('btn-clip-full.svg')" }} className={`facebook-share-btn ${!isTermsChecked ? 'share-disabled' : ''}`}

                        >
                            <a style={{ marginLeft: '32%', textDecoration: 'none', color: 'black' }}
                                target="_blank"
                                href={`https://www.facebook.com/sharer/sharer.php?u=${API.FACEBOOK_SHARE_URL}${scoreFileName}`} class="fb-xfbml-parse-ignore">
                            <div style={{ marginLeft: '34%'}}> แชร์บน Facebook
                                <img src="facebook-icon-share.png" alt="Button Icon" style={{ marginLeft: '5px' }} /></div>

                            </a>


                        </div> */}

                        {/* <div className="fb-share-button" 
                            data-href={`${API.FACEBOOK_SHARE_URL}${scoreFileName}`}
                            data-layout="button" data-size="large"> 
                        </div> */}
                    </div>
                </Row>
            </Container>
            {
                isShowTerms && <Modal show={isShowTerms} onHide={handleModalClose} style={{ paddingTop: '30px' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms & Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '900px', overflow: 'auto', padding: '1px' }}>
                        <Card.Text>
                            <div className="tc-quiz-popup">
                                <ol type="a">
                                    <li> บริษัท ซันโทรี่ เบเวอเรจ แอนด์ ฟู้ด (ประเทศไทย) จำกัด (“บริษัท”) ได้จัดกิจกรรม “Carnosine Mission” ระหว่างวันที่ 1 มกราคม  2567 – 30 มิถุนายน 2567 </li>
                                    <li> เงื่อนไขกิจกรรม </li>
                                    <ol type="i">
                                        <li> ผู้สนใจสามารถเข้าร่วมกิจกรรมผ่าน<a className="text-primary text-decoration-underline" target='blank' href=" https://www.carnosinecenter.com">เว็บไซต์</a>  โดยการ</li>
                                        <ol type="1">
                                            <li> ร่วมตอบคำถามทั้งหมด 4 ข้อเกี่ยวกับ คาร์โนซีน </li>
                                            <li> กรอกข้อมูลส่วนตัว ได้แก่ ชื่อ-นามสกุล (ตามบัตรประชาชน) หมายเลขโทรศัพท์ อีเมล วันเดือนปีเกิด และที่อยู่  </li>
                                            <li>แชร์ผลคะแนนจากการตอบคำถามทั้งหมด 4 ข้อ บน Facebook ส่วนตัวของผู้ร่วมกิจกรรม พร้อมตั้ง caption และตั้งค่าโพสต์เป็นสาธารณะ (Public) พร้อมใส่ Hashtag #Carnosine และ #Boostbrain </li>
                                            <li> โพสต์ที่มีจำนวนยอดไลค์ (like) สูงสุดเป็นอันดับแรกในแต่ละเดือน จะได้รับรางวัล จำนวน 1 รางวัลต่อเดือน (รวมทั้งสิ้น 6 รางวัลตลอดระยะเวลาของกิจกรรม)</li>
                                            <ol type="i">
                                                <li>รางวัลประจำเดือน มกราคม 2567 คือ iPad Pro ขนาด 11.9 นิ้ว ความจุ 256  GB มูลค่า 36,900 บาท</li>
                                                <li>รางวัลประจำเดือน กุมภาพันธ์ 2567 คือ Samsung Galaxy S24 Ultra ความจุ 512 GB มูลค่า 49,900 บาท </li>
                                                <li>รางวัลประจำเดือน มีนาคม 2567 คือ Macbook Air รุ่น 13 นิ้ว (M2) ความจุ 512 GB มูลค่า 49,900 บาท</li>
                                                <li>รางวัลประจำเดือน เมษายน 2567 คือ เครื่องฟอกอากาศ Dyson Purifier มูลค่า 32,900 บาท</li>
                                                <li>รางวัลประจำเดือน พฤษภาคม 2567 คือ ทองคำ 1 บาท มูลค่า 34,700 บาท (ราคา ณ วันที่ 20 ธันวาคม 2566)</li>
                                                <li>รางวัลประจำเดือน มิถุนายน 2567 คือ PlayStation 5 มูลค่า 18,690 บาท</li>
                                            </ol>
                                        </ol>
                                    </ol>
                                    <li>บริษัทจะประกาศรายชื่อผู้ได้รับรางวัล ประจำเดือน ในทุกๆ วันที่ 18 ของเดือนถัดไป (ตั้งแต่ กุมภาพันธ์ - กรกฏาคม 2567) ผ่านทาง <a className="text-primary text-decoration-underline" target='blank' href="https://www.facebook.com/profile.php?id=61553884481695">Carnosine Center Facebook Page</a> และ <a className="text-primary text-decoration-underline" target='blank' href="https://www.carnosinecenter.com">เว็บไซต์</a> </li>
                                    <li>ในกรณีที่มีผู้ได้รับยอดไลค์สูงสุด มากกว่า 1 คน คณะกรรมการของบริษัทจะพิจารณาจาก Caption เพิ่มเติม โดยคำตัดสินของคณะกรรมการถือเป็นที่เด็ดขาดและสิ้นสุด </li>
                                    <li>ผู้ที่ได้รับรางวัลประจำเดือนใด ต้องติดต่อขอรับรางวัลผ่านทาง Inbox Facebook: Carnosine Center ภายในวันที่ 20 กุมภาพันธ์ - กรกฏาคม 2567ของเดือนถัดไป มิฉะนั้น จะถือว่าสละสิทธิ์ และบริษัทจะจัดให้ผู้ได้ที่มียอดไลค์ (Like) สูงสุดในลำดับถัดไปได้รับรางวัลแทน</li>
                                    <li>ผู้ที่ได้รับรางวัล จะต้องมารับรางวัลด้วยตนเอง โดยต้องแสดงบัตรประชาชนพร้อมสำเนาเพื่อประกอบการรับรางวัล หรือ สามารถมอบอำนาจให้กับผู้รับมอบอำนาจมารับของรางวัลได้ โดยต้องนำหนังสือมอบอำนาจ ติดอากรแสตมป์ 10 บาท พร้อมแนบสำเนาบัตรประชาชนของผู้มอบอำนาจและผู้รับมอบอำนาจที่ลงนามรับรองสำเนาถูกต้องมาเพื่อประกอบการรับรางวัลด้วย้</li>
                                    <li>บริษัทได้มอบหมายให้ บริษัท ลัคกี้วันกรุ๊ป จำกัด เป็นคนผู้ส่งมอบของรางวัลข้างต้น ผู้ได้รับรางวัลจะต้องมารับรางวัลภายใน 30 วันนับจากวันที่ประกาศผล มิฉะนั้น จะถือว่าสละสิทธิ์ และบริษัทจะจัดให้ผู้ได้ที่มียอดไลค์ (Like) สูงสุดในลำดับถัดไปได้รับรางวัลแทน</li>
                                    <li>ของรางวัลทั้งหมดไม่สามารถเปลี่ยนแปลงเป็นเงินสดหรือของอื่นใดได้ และไม่สามารถโอนสิทธิ์ให้ผู้อื่นได้</li>
                                    <li>ผู้ได้รับรางวัลมูลค่าเกิน 1,000 บาท จะต้องชำระค่าภาษี หัก ณ ที่จ่าย 5% ของมูลค่ารางวัล </li>
                                    <li>ผู้ร่วมกิจกรรมทุกท่านยอมรับว่าได้อ่านและเข้าใจในเงื่อนไขของกิจกรรมก่อนการเข้าร่วมรายการแล้ว</li>
                                    <li>ผู้ร่วมกิจกรรมได้ที่มียอดไลค์ (Like)สูงสุดรับทราบถึงวัตถุประสงค์ของบริษัทในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้เข้าร่วมกิจกรรม ซึ่งได้แก่ ชื่อ – นามสกุล ที่อยู่ หมายเลขโทรศัพท์ และข้อมูลส่วนบุคคลที่ปรากฏบนบัตรประชาชน เพื่อใช้ในตรวจสอบความถูกต้องและส่งมอบของรางวัล ทั้งนี้ การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรมจะเป็นไปเท่าที่จำเป็นตามวัตถุประสงค์ที่ได้แจ้งไว้เท่านั้น กรณีที่ผู้ร่วมกิจกรรมไม่ประสงค์ให้บริษัทจัดเก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรมดังกล่าว ย่อมส่งผลให้บริษัทไม่สามารถดำเนินการติดต่อผู้ร่วมกิจกรรมได้ และอาจทำให้ผู้ร่วมกิจกรรมเสียสิทธิการได้รับรางวัลตามกิจกรรมนี้</li>
                                    <li>ผู้ร่วมกิจกรรมยอมรับว่าได้อ่านประกาศเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของบริษัทที่ QR Code ด้านล่างแล้วก่อนเข้าร่วมกิจกรรม <a className="text-primary text-decoration-underline" target='blank' href="https://www.brandsworld.co.th/privacy-policy.html?fbclid=IwAR2RJnX-C9qeYitRS68ns-4tCN-9E78DqT0x6ODLn-ZQ-CDBrOxhuBcbco0#clickloc=footer">นโยบายความเป็นส่วนตัว</a></li>
                                </ol>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        height: '100px',
                                        width: '100px',
                                    }}>
                                        <img style={{
                                            height: '100%',
                                            width: '100%',
                                        }} src='quiz-qr.png' alt='QR Code' />
                                    </div>
                                </div>

                            </div>
                        </Card.Text>
                    </Modal.Body>

                </Modal>
            }
        </div>
    );
};

export default QuizShare;