import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Reset, Update_data, quizScreen } from "../../Redux/action";
import { Button } from 'react-bootstrap';
import { getQuizCampaignCount } from "../../Services/Services";
import ClipLoader from "react-spinners/ClipLoader";
import { useData } from "../../utils/dataContext";
import QuizLoginDirect from "../QuizLogin/QuizLoginDirect";

export default function QuizResultDirect(props) {

    const datas = {
        name: "",
        mobile: "",
        email: "",
        profPic: "",
        platform: "",
        platformId: "",
        idToken: ""
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { updateScreen, currentScreen } = useData();
    const { quizDetails } = useSelector(state => state);


    const [isToast, setIsToast] = useState(false);
    const [error, setError] = useState()
    const [message, setMessage] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isQuizLogin, setIsQuizLogin] = useState(false);
    const [campaignUserCount, setCampaignUserCount] = useState(0);
    const [rankName, setRankName] = useState('');

    useEffect(() => {
        let currentUrl = window.location.href;
        let params = currentUrl.split('?')[1];

        if (currentScreen == 'start-quiz' && params == undefined) {
            navigate('/start-quiz');
        } else {
            updateScreen('quiz-result');
            dispatch(quizScreen('quizResult'))

            let score = quizDetails.scorePercentage ?? 0;
            if (score == 0 || score == 25) {
                setRankName('Carnosine Researcher')
            } else if (score == 50) {
                setRankName('Carnosine Assistant')
            } else if (score == 75) {
                setRankName('Carnosine Associate')
            } else {
                setRankName('Carnosine Professor')
            }

            let campaignId = 3;
            getQuizCampaignCount(campaignId)
                .then((res) => {
                    setCampaignUserCount(res.data)
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                    console.error(err);
                });
        }
    }, [])

    const onClickLogin = () => {
        // navigate('/quiz-login');
        setIsQuizLogin(true)
    };

    return (
        <div>
            {
                isLoading ?
                    <div className="loader-container">
                        <div className="loader-wrapper">
                            <ClipLoader
                                loading={true}
                                color="white"
                                size={50}
                                aria-label="Loading Spinner"
                            />
                        </div>
                    </div>
                    :
                    (
                        !isQuizLogin ? (<div>
                            <div className="mission-section">
                                <div className="c-outer-circle">
                                    <div className="box">
                                        <img src="ring-line.svg"></img>
                                    </div>
                                </div>
                                <div className="brain content">
                                    <img className="brain-center-result" style={{ opacity: 1, }}
                                        src="brain-1.png"></img>
                                    <img className="brain-center-result" style={{ opacity: 1, }}
                                        src="brain-1.png"></img>
                                </div>
                                <div className="d-flex justify-content-center c-content-heading mar-top-50">
                                    <h4 >Mission</h4>

                                </div>
                                <div className="d-flex justify-content-center c-content-heading mar-top-10">
                                    <h4 style={{ color: 'palegoldenrod' }}>{`${props?.scorePercentage ?? '-'}%`}</h4>

                                </div>
                                <div className="d-flex justify-content-center c-content-heading mar-top-10">
                                    {/* Completed */}
                                    <h4>Completed </h4>
                                </div>
                                {/* <h4 className="mar-top-10" style={{ color: 'palegoldenrod', display: 'flex', justifyContent: 'space-around' }}>No. {campaignUserCount + 1} </h4> */}

                            </div>
                            <div className="answer" >
                                <div className="e-result" style={{ backgroundImage: "url('DialogBg.png')" }}>
                                    <p style={{ color: 'palegoldenrod' }}>
                                        {/* You are now a */}
                                        คุณคือ
                                    </p>
                                    <div className="e-rank">
                                        <p style={{ color: 'palegoldenrod' }}>{`${rankName}`}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="d-flex justify-content-center c-content-heading">
                                <h4 >
                                    {/* Login & Share */}
                                    ลงทะเบียน
                                </h4>
                            </div>
                            {/* <div className="d-flex justify-content-center c-content-heading mar-top-10">
                            <h4 >to win prize</h4>
                        </div> */}
                            <div className='d-flex justify-content-center align-item-center pad-tb-10'> <Button onClick={onClickLogin}
                                style={{ backgroundImage: "url('btn-clip-full.svg')" }} className='start-btn'>
                                {/* Continue */}ต่อไป
                            </Button></div>
                        </div>) : (
                            <QuizLoginDirect
                                quizData={props.quizData}
                            />
                        )
                    )
            }

        </div>
    );
}   