import * as types from "./types";

export const Update_data = (payload) => {
    return {
        type: types.STORE_USER_DATA,
        payload: payload
    }
};

export const quizDetails = (payload) => {
    return {
        type: types.QUIZ_DETAILS,
        payload: payload
    }
};

export const windowPath = (payload) => {
    return {
        type: types.WINDOW_PATH,
        payload: payload
    }
};

export const quizScreen = (payload) => {
    return {
        type: types.QUIZ_SCREEN,
        payload: payload
    }
};

export const Logout = () => {
    return {
        type: types.LOGOUT,
        
    }
};

export const updateOrderID = (payload) => {
    return {
        type: types.STORE_ORDERID,
        payload:payload
        
    }
};

export const isLoading =(payload)=>{
    return {
        type: types.LOADING,
        payload:payload
        
    }
}

export const setMessageNew =(payload)=>{
    return {
        type: types.SETMESSAGE,
        payload:payload
        
    }
}

export const Reset =()=>{
    return {
        type: types.RESET,
        
        
    }
}
