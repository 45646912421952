import { React, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Mission from "../Mission/Mission";
import { Button, Alert, Modal, Card, CloseButton, Container, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { quizScreen, Reset } from "../../Redux/action";
import { getQuestionnaires } from "../../Services/Services";
import ClipLoader from "react-spinners/ClipLoader";
import { useData } from "../../utils/dataContext";

const StartQuiz = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateScreen } = useData();

    const [quizQuestions, setQuizQuestions] = useState([]);
    const [isShowQuiz, setIsShowQuiz] = useState(false);
    const [isErrorAlert, setIsErrorAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentMissionNo, setCurrentMissionNo] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [missionCompleted, setMissionCompleted] = useState(25);
    const [scorePercentage, setScorePercentage] = useState(0);
    const [isShowTerms, setIsShowTerms] = useState(false);
    const [isShowIPadModal, setIsShowIpadModal] = useState(false);

    useEffect(() => {
        dispatch(Reset())
        dispatch(quizScreen('start-quiz'))
        updateScreen('start-quiz');
        if (quizQuestions?.length == 0) {
            setIsLoading(true);
            getQuizQuestions();
        }
    }, [])

    const handleModalClose = () => setIsShowTerms(false);
    const handleModalShow = () => setIsShowTerms(true);

    const getQuizQuestions = () => {
        const campaignId = 3;
        getQuestionnaires(campaignId)
            .then((res) => {
                setIsLoading(false)
                let response = res?.data ?? [];
                if (response?.length > 0) {
                    let addingMissions = response.map((question, index) => ({ ...question, missionNo: index + 1 }));
                    setQuizQuestions(addingMissions);
                } else {
                    setQuizQuestions([]);
                }
            })
            .catch((err) => {
                setIsLoading(false)
                console.error(err);
            });
    }

    const onClickStart = () => {
        setIsErrorAlert(false);
        if (quizQuestions?.length > 0) {
            setIsShowQuiz(!isShowQuiz);
            dispatch(quizScreen('mission'))
        } else {
            setIsErrorAlert(true);
            getQuizQuestions();
            clearTimeout(timeout);
        }
    };

    const handleOnClickiPad = () => {
        setIsShowIpadModal(!isShowIPadModal);
    }

    const handleIPadModalClose = () => setIsShowIpadModal(false);

    const timeout = setTimeout(() => {
        setIsErrorAlert(false);
    }, 5000);

    const onClickNext = () => {
        setCurrentMissionNo(prevState => prevState + 1)
        setMissionCompleted(prevState => {
            let updatedValue = prevState + 25;
            return updatedValue <= 100 ? updatedValue : prevState;
        });
    }


    const onClickOption = (option) => {
        let currentData = quizQuestions[currentMissionNo - 1];
        setSelectedOptions(prevState => [
            ...prevState,
            { questionId: currentData?.questionId, answerId: option.optionId, answer: option.option }
        ]);
        setScorePercentage(prevState => {
            let updatedValue = prevState + 25;
            return option.isCorrect ? updatedValue : prevState;
        });
    }

    return (
        <div>
            {isLoading ? <div className="loader-container">
                <div className="loader-wrapper">
                    <ClipLoader
                        loading={true}
                        color="white"
                        size={50}
                        aria-label="Loading Spinner"
                    />
                </div>
            </div> :
                <div className='quiz-start'>
                    {/* <div className='art-work-particle'><img src='art-work-particle.svg'></img></div> */}
                    {isShowQuiz ?
                        quizQuestions?.length > 0 &&
                        <Mission
                            quiz={quizQuestions[currentMissionNo - 1]}
                            currentMissionNo={currentMissionNo}
                            onClickNext={onClickNext}
                            onClickOption={onClickOption}
                            setMissionCompleted={setMissionCompleted}
                            missionCompleted={missionCompleted}
                            scorePercentage={scorePercentage}
                            selectedOptions={selectedOptions}
                            isLastQuiz={quizQuestions?.length == currentMissionNo ? true : false}
                        />
                        :
                        <div>

                            <div className='content-text'>
                                <h3>บูสต์สมองของคุณให้พร้อมกับ Mission Carnosine</h3>
                                <p style={{ fontSize: '15px', marginBottom: '0px' }}>
                                    ( มีสิทธิ์รับ สร้อยคอทองคำหนัก
                                    <a onClick={handleOnClickiPad} style={{ textDecoration: 'underline', cursor: "pointer" }}> 1 บาท มูลค่า </a>
                                    มูลค่า 34,700 บาท )
                                </p>
                                <p style={{ fontSize: '14px', marginBottom: '0px' }}>*ของรางวัลประจำเดือน พฤษภาคม 2567</p>
                            </div>
                            {/* <div className='img-element'>
                                <img src='brain-start-1.png'></img>
                            </div> */}
                            <div className='img-element-main'>
                                <img src='image-bk-start.png'></img>
                            </div>
                            {/* <div className='img-element e-graph'>
                                <img src='graph.png'></img>
                            </div> */}
                            {/* <div className='layer-element'>
                                <img src='layer-start.svg'></img>
                            </div> */}
                            <div className='d-flex justify-content-center align-item-center pad-tb-20'> <Button onClick={onClickStart}
                                style={{ backgroundImage: "url('btn-clip-full.svg')" }} className='start-btn'>Start</Button>
                            </div>
                            <div className='d-flex justify-content-center align-item-center'>
                                <a onClick={handleModalShow} style={{ textDecoration: 'underline', color: 'white', cursor: "pointer" }}>
                                    {/* Terms & Conditions */}
                                    ข้อตกลงและเงื่อนไข
                                </a>
                            </div>
                            {
                                isErrorAlert &&
                                <div style={{ position: "absolute", top: 10, left: 0, right: 0, zIndex: 999 }}>
                                    <Alert key={'danger'} variant={'danger'} dismissible>
                                        Error in fetching quiz questions. Please try again later.
                                    </Alert>
                                </div>

                            }
                        </div>
                    }
                </div>}
            {
                isShowIPadModal && <Modal show={isShowIPadModal} onHide={handleIPadModalClose} style={{ paddingTop: '30px' }}>
                    {/* <Modal.Header closeButton>
                    </Modal.Header> */}
                    <Modal.Body style={{ minHeight: '500px' }}>
                        <Card.Text>
                            <Container >
                                <Row className="justify-content-end">
                                    <CloseButton onClick={handleIPadModalClose} />
                                </Row>
                                <Row >
                                    <div className="iPadPro">
                                        <img src='necklace-gold-money-jewellery.png'></img>
                                    </div>
                                    {/* <div className="iPadPro">
                                        <p style={{ fontSize: '14px', marginTop: '15px', marginBottom: '0px' }}>มีสิทธิ์รับ Dyson Purifier มูลค่า 32,900 บาท</p>
                                    </div>
                                    <div className="iPadPro">
                                        <p style={{ fontSize: '12px', marginBottom: '0px', whiteSpace: 'pre-wrap' }}>*ของรางวัลประจำเดือน เมษายน 2567</p>
                                    </div> */}
                                </Row>
                            </Container>
                        </Card.Text>
                    </Modal.Body>
                </Modal>
            }
            {
                isShowTerms && <Modal show={isShowTerms} onHide={handleModalClose} style={{ paddingTop: '30px' }}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: '15px' }}>เงื่อนไขการร่วมกิจกรรม Carnosine Mission</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '900px', overflow: 'auto', padding: '1px' }}>
                        <Card.Text>
                            <div className="tc-quiz-popup">
                                <ol type="a">
                                    <li> บริษัท ซันโทรี่ เบเวอเรจ แอนด์ ฟู้ด (ประเทศไทย) จำกัด (“บริษัท”) ได้จัดกิจกรรม “Carnosine Mission” ระหว่างวันที่ 1 มกราคม  2567 – 30 มิถุนายน 2567 </li>
                                    <li> เงื่อนไขกิจกรรม </li>
                                    <ol type="i">
                                        <li> ผู้สนใจสามารถเข้าร่วมกิจกรรมผ่าน<a className="text-primary text-decoration-underline" target='blank' href="https://www.carnosinecenter.com">เว็บไซต์</a>  โดยการ</li>
                                        <ol type="1">
                                            <li> ร่วมตอบคำถามทั้งหมด 4 ข้อเกี่ยวกับ คาร์โนซีน </li>
                                            <li> กรอกข้อมูลส่วนตัว ได้แก่ ชื่อ-นามสกุล (ตามบัตรประชาชน) หมายเลขโทรศัพท์ อีเมล วันเดือนปีเกิด และที่อยู่  </li>
                                            <li>แชร์ผลคะแนนจากการตอบคำถามทั้งหมด 4 ข้อ บน Facebook ส่วนตัวของผู้ร่วมกิจกรรม พร้อมตั้ง caption และตั้งค่าโพสต์เป็นสาธารณะ (Public) พร้อมใส่ Hashtag #Carnosine และ #Boostbrain </li>
                                            <li> โพสต์ที่มีจำนวนยอดไลค์ (like) สูงสุดเป็นอันดับแรกในแต่ละเดือน จะได้รับรางวัล จำนวน 1 รางวัลต่อเดือน (รวมทั้งสิ้น 6 รางวัลตลอดระยะเวลาของกิจกรรม)</li>
                                            <ol type="i">
                                                <li>รางวัลประจำเดือน มกราคม 2567 คือ iPad Pro ขนาด 11.9 นิ้ว ความจุ 256  GB มูลค่า 36,900 บาท</li>
                                                <li>รางวัลประจำเดือน กุมภาพันธ์ 2567 คือ Samsung Galaxy S24 Ultra ความจุ 512 GB มูลค่า 49,900 บาท </li>
                                                <li>รางวัลประจำเดือน มีนาคม 2567 คือ Macbook Air รุ่น 13 นิ้ว (M2) ความจุ 512 GB มูลค่า 49,900 บาท</li>
                                                <li>รางวัลประจำเดือน เมษายน 2567 คือ เครื่องฟอกอากาศ Dyson Purifier มูลค่า 32,900 บาท</li>
                                                <li>รางวัลประจำเดือน พฤษภาคม 2567 คือ ทองคำ 1 บาท มูลค่า 34,700 บาท (ราคา ณ วันที่ 20 ธันวาคม 2566)</li>
                                                <li>รางวัลประจำเดือน มิถุนายน 2567 คือ Play Station 5 มูลค่า 18,690 บาท</li>
                                            </ol>
                                        </ol>
                                    </ol>
                                    <li>บริษัทจะประกาศรายชื่อผู้ได้รับรางวัล ประจำเดือน ในทุกๆ วันที่ 18 ของเดือนถัดไป (ตั้งแต่ กุมภาพันธ์ - กรกฏาคม 2567) ผ่านทาง <a className="text-primary text-decoration-underline" target='blank' href="https://www.facebook.com/profile.php?id=61553884481695">Carnosine Center Facebook Page</a> และ <a className="text-primary text-decoration-underline" target='blank' href="https://www.carnosinecenter.com/">เว็บไซต์</a> </li>
                                    <li>ในกรณีที่มีผู้ได้รับยอดไลค์สูงสุด มากกว่า 1 คน คณะกรรมการของบริษัทจะพิจารณาจาก Caption เพิ่มเติม โดยคำตัดสินของคณะกรรมการถือเป็นที่เด็ดขาดและสิ้นสุด </li>
                                    <li>ผู้ที่ได้รับรางวัลประจำเดือนใด ต้องติดต่อขอรับรางวัลผ่านทาง Inbox Facebook: Carnosine Center ภายในวันที่ 20 กุมภาพันธ์ - กรกฏาคม 2567ของเดือนถัดไป มิฉะนั้น จะถือว่าสละสิทธิ์ และบริษัทจะจัดให้ผู้ได้ที่มียอดไลค์ (Like) สูงสุดในลำดับถัดไปได้รับรางวัลแทน</li>
                                    <li>ผู้ที่ได้รับรางวัล จะต้องมารับรางวัลด้วยตนเอง โดยต้องแสดงบัตรประชาชนพร้อมสำเนาเพื่อประกอบการรับรางวัล หรือ สามารถมอบอำนาจให้กับผู้รับมอบอำนาจมารับของรางวัลได้ โดยต้องนำหนังสือมอบอำนาจ ติดอากรแสตมป์ 10 บาท พร้อมแนบสำเนาบัตรประชาชนของผู้มอบอำนาจและผู้รับมอบอำนาจที่ลงนามรับรองสำเนาถูกต้องมาเพื่อประกอบการรับรางวัลด้วย้</li>
                                    <li>บริษัทได้มอบหมายให้ บริษัท ลัคกี้วันกรุ๊ป จำกัด เป็นคนผู้ส่งมอบของรางวัลข้างต้น ผู้ได้รับรางวัลจะต้องมารับรางวัลภายใน 30 วันนับจากวันที่ประกาศผล มิฉะนั้น จะถือว่าสละสิทธิ์ และบริษัทจะจัดให้ผู้ได้ที่มียอดไลค์ (Like) สูงสุดในลำดับถัดไปได้รับรางวัลแทน</li>
                                    <li>ของรางวัลทั้งหมดไม่สามารถเปลี่ยนแปลงเป็นเงินสดหรือของอื่นใดได้ และไม่สามารถโอนสิทธิ์ให้ผู้อื่นได้</li>
                                    <li>ผู้ได้รับรางวัลมูลค่าเกิน 1,000 บาท จะต้องชำระค่าภาษี หัก ณ ที่จ่าย 5% ของมูลค่ารางวัล </li>
                                    <li>ผู้ร่วมกิจกรรมทุกท่านยอมรับว่าได้อ่านและเข้าใจในเงื่อนไขของกิจกรรมก่อนการเข้าร่วมรายการแล้ว</li>
                                    <li>ผู้ร่วมกิจกรรมได้ที่มียอดไลค์ (Like)สูงสุดรับทราบถึงวัตถุประสงค์ของบริษัทในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้เข้าร่วมกิจกรรม ซึ่งได้แก่ ชื่อ – นามสกุล ที่อยู่ หมายเลขโทรศัพท์ และข้อมูลส่วนบุคคลที่ปรากฏบนบัตรประชาชน เพื่อใช้ในตรวจสอบความถูกต้องและส่งมอบของรางวัล ทั้งนี้ การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรมจะเป็นไปเท่าที่จำเป็นตามวัตถุประสงค์ที่ได้แจ้งไว้เท่านั้น กรณีที่ผู้ร่วมกิจกรรมไม่ประสงค์ให้บริษัทจัดเก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรมดังกล่าว ย่อมส่งผลให้บริษัทไม่สามารถดำเนินการติดต่อผู้ร่วมกิจกรรมได้ และอาจทำให้ผู้ร่วมกิจกรรมเสียสิทธิการได้รับรางวัลตามกิจกรรมนี้</li>
                                    <li>ผู้ร่วมกิจกรรมยอมรับว่าได้อ่านประกาศเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของบริษัทที่ QR Code ด้านล่างแล้วก่อนเข้าร่วมกิจกรรม <a className="text-primary text-decoration-underline" target='blank' href="https://www.brandsworld.co.th/privacy-policy.html?fbclid=IwAR2RJnX-C9qeYitRS68ns-4tCN-9E78DqT0x6ODLn-ZQ-CDBrOxhuBcbco0#clickloc=footer">นโยบายความเป็นส่วนตัว</a></li>
                                </ol>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        height: '100px',
                                        width: '100px',
                                    }}>
                                        <img style={{
                                            height: '100%',
                                            width: '100%',
                                        }} src='quiz-qr.png' alt='QR Code' />
                                    </div>
                                </div>

                            </div>
                        </Card.Text>
                    </Modal.Body>

                </Modal>
            }
        </div>

    )
}


export default StartQuiz