import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();
export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [quizDetails, setQuizDetails] = useState('Initial data');
  const [currentScreen, setCurrentScreen] = useState('start-quiz');
  const [isRegistered, setIsRegistered] = useState(false);

  const updateData = newData => {
    setQuizDetails(newData);
  };

  const updateScreen = newData => {
    setCurrentScreen(newData);
  };

  return (
    <DataContext.Provider value={{ quizDetails, updateData, currentScreen, updateScreen, isRegistered, setIsRegistered }}>
      {children}
    </DataContext.Provider>
  );
};
