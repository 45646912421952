import * as types from './types';

const initialState = {
  userData: "",
  isLoggedIn: false,
  orderid: "",
  isLoading: false,
  Message: "initial stage",
  windowPath: true,
  quizScreen: 'startQuiz',
  quizDetails: {}
};



const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_USER_DATA:
      return {
        ...state,
        userData: action.payload,
        isLoggedIn: true,
      };
      case types.QUIZ_DETAILS:
      return {
        ...state,
        quizDetails: action.payload
      };
    case types.WINDOW_PATH:
      return {
        ...state,
        windowPath: action.payload,
      };
      case types.QUIZ_SCREEN:
      return {
        ...state,
        quizScreen: action.payload,
      };
    case types.LOGOUT:
      return initialState
    case types.STORE_ORDERID:
      return {
        ...state,
        orderid: action.payload

      }
    case types.LOADING:
      return {
        ...state,
        isLoading: action.payload

      }
    case types.RESET:
      return {
        ...initialState
      }
    case types.SETMESSAGE:
      return {
        ...state,
        Message: action.payload
      }
    default:
      return state;
  }
};

export default MainReducer;