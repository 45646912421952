import React, { useEffect, useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { SignUp,LoginForm } from "../../Services/Services";
import { signInWithPopup } from "firebase/auth";
import { auth,provider,FbProvider} from "../../firebaseConfig";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Update_data } from "../../Redux/action";
import { API } from "../../Constants/Constants";
import liff from '@line/liff';
import Loader from "../Loader/Loader";

export const Social =()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [confirmPwd, setConfirmPwd] = useState();
  const [errors, setErrors] = useState({});
  const [pwdError,setPwdError] = useState(false);
  const [message,setMessage] = useState();
const [profile,setProfile] = useState();
const [isLoading,setIsLoading] = useState(false);
const datas ={
  name: "",
  mobile:"",
  email: "",
  profPic: "",
  platform: "",
  platformId: "",
  idToken: ""
}
    const signin=()=>{
        signInWithPopup(auth,provider).then((data)=>
        {   
            if(data.user.phoneNumber===null){
              datas.mobile = ""
            }else{
              datas.mobile=data.user.phoneNumber
            }
            datas.name=data.user.displayName
            datas.email=data.user.email
            datas.profPic = data.user.photoURL
            datas.platform = "Google"
            datas.platformId= data.user.uid
            datas.idToken = data.user.accessToken

            setIsLoading(true)
            LoginForm(JSON.stringify(datas)).then(res=>{
              dispatch(Update_data(res.data))
              navigate('/register')
              
            }).catch(err=>{
              setIsLoading(false)
              console.log(err)
            })
             
        }
        ).catch((error) => console.error(error.message));
    }
    
    const FbSignin=()=>{
        signInWithPopup(auth,FbProvider).then((data)=>{
          console.log(data.user.accessToken)
          if(data.user.phoneNumber===null){
            datas.mobile = ""
          }else{
            datas.mobile=data.user.phoneNumber
          }
          datas.name=data.user.displayName
          datas.email=data.user.email
          datas.profPic = data.user.photoURL
          datas.platform = "Facebook"
          datas.platformId= data.user.uid
          datas.idToken = data.user.accessToken
           
          console.log(datas)
          setIsLoading(true)
          LoginForm(JSON.stringify(datas)).then(res=>{
            dispatch(Update_data(res.data))
            navigate('/register')
          }).catch(err=>{
            setIsLoading(false)
            console.log(err)
          })
            
        }).catch((error)=>{
            console.log(error)
        })
    }
    // const getUserProfile = async () => {
    //   try {
    //     const userProfile = await liff.getProfile();
    //     console.log(userProfile);
    //     // Process the user profile data as needed
    //   } catch (error) {
    //     console.error('Failed to get user profile:', error);
    //   }
    // };
    
    const LineLogin = async () => {
      try{
          liff.login()
          setIsLoading(true)
      } catch(error){
          console.error("not work")
      }   
      }
      
     

   
    return(
        <div>
           {!!isLoading? <Loader size={{width:"150px",height:"150px"}}/>:<div className="mt-4 d-flex column-gap-5 justify-content-center">
    
    <button className="social" onClick={signin}><img src={require('../../assets/google.png')} width="100%" alt=""/></button>
    {/* <button className="social" onClick={FbSignin}><img src={require('../../assets/facebook.png')} width="100%" alt=""/></button> */}
    <button className="social" onClick={LineLogin}><img src={require('../../assets/line.png')} width="100%" alt=""/></button>
    </div>}
            
        </div>
    )
}