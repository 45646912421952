import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, HashRouter } from 'react-router-dom';
import Login from '../Components/Login/Login';
import StartQuiz from '../Components/start-quiz/startQuiz';
import QuizLoginDirect from '../Components/QuizLogin/QuizLoginDirect';
import QuizResult from '../Components/QuizResult/QuizResult';
import QuizResultDirect from '../Components/QuizResult/QuizResultDirect';
import QuizShare from '../Components/QuizShare/QuizShare';
import { useSelector } from 'react-redux';
import { useData } from '../utils/dataContext';
import LoginScreen from '../Components/Screens/loginscreen';

export default function Router() {


	return (

		<HashRouter>
			<Routes >
				<Route path='/' element={<QuizResultDirect />} />
				{/* <Route path='/quiz-result' element={<QuizResult />} /> */}
				<Route path='/start-quiz' element={<StartQuiz />} />
				{/* <Route path='/quiz-login' element={<QuizLoginDirect />} /> */}
				<Route path='/quiz-share' element={<QuizShare />} />
				<Route path="*" element={<Navigate to="/start-quiz" />} />
			</Routes>
		</HashRouter>

	);
}
