export const API = {
  // BASE_URL: 'https://uqf3ehhfm73szmyvp4qpw4f5oe0adtle.lambda-url.ap-southeast-1.on.aws', //UAT-aws
  BASE_URL: 'https://j86upkg087.execute-api.ap-southeast-1.amazonaws.com/prod', //live-aws

  // BASE_URL:"https://suntory-az-api-uat.azurewebsites.net", //UAT Azure
  // BASE_URL:"https://md-bw-api-live.azurewebsites.net", //LIVE Azure
  LOGIN: "/api/Customer/LoginCustomer",
  POSTAL: "/api/Common/GetPostal",
  ECOM: "/api/Common/GetEcommerce",
  REGISTER: "/api/Campaign/RegisterCampaign",
  CAMPAIGN_QUESTIONNAIRES: "/api/Campaign/GetQuestionnaire",
  CAMPAIGN_USERS_COUNT: '/api/Campaign/GetCampaignUsersCount',
  // VITE_LIFF_ID:'2000124516-mLgeVGn9', //Live- mothersday
  VITE_LIFF_ID: '2002103236-GOdQad0d', //Live- quiz-campaign
  LIFF_ID_UAT: '1661510710-BMzg0xZK', // UAT
  //FACEBOOK_SHARE_URL:'https://suntory.tcit.ae/', // UAT
  FACEBOOK_SHARE_URL: 'https://www.carnosinecenter.com/', //live
  FACEBOOK_TOKEN: 'EAAcWevhG9UUBO1GLsmHBqT0jzqWxSEOzeyVdGc1QOcZBZBw0ED3k2BOH6351FtgZBKdG6ea34A6AmjaZCAPBLij5U8qZABEYvT15EhgTlaBiHap8G561RcVq2g41VN8ZAp7eZAluK7ZCErSm9KDjHLP8ZBbpztLheEUZAAcVVS8zcVigLRvr8T3lf0nXDk'

}
