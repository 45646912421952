import axios from 'axios';
import { API } from '../Constants/Constants';

export const LoginForm = (data) => {
    return new Promise((resolve, reject) => {
      var config = {
        method:"post",
        url:`${API.BASE_URL}${API.LOGIN}`,
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*"
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }

  export const SignUp = (data) => {
    return new Promise((resolve, reject) => {
      var config = {
        method:"post",
        url:`${API.SIGNUP}`,
        headers: {
           'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }

  export const userById = (id) => {
    return new Promise((resolve, reject) => {
      var config = {
        method:"get",
        url:`${API.SIGNUP}/${id}`,
        headers: {
           'Content-Type': 'application/json'
        },
        
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }


  export const postalCode = (code,token) => {
    return new Promise((resolve, reject) => {
      var config = {
        method:"get",
        url:`${API.BASE_URL}${API.POSTAL}?zip=${code}`,
        headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${token}`
        },
        
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }

  export const EcomDropDown = (code,token) => {
    return new Promise((resolve, reject) => {
      var config = {
        method:"get",
        url:`${API.BASE_URL}${API.ECOM}`,
        headers: {
           'Content-Type': 'application/json',
        },
        
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }


  export const CampaignRegister = (token,data) => {
    let headers = {};
    if(data.campaignid === 3){
       headers = {
        'Content-Type': 'application/json'
     }
    }else{
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
     }
    }
    return new Promise((resolve, reject) => {
      var config = {
        method:"post",
        url:`${API.BASE_URL}${API.REGISTER}`,
        headers: headers,
        data:data
        
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error)
        });
  
    })
  }

  export const OrderIdValidate = (id,token) => {
    return new Promise((resolve, reject) => {
      var config = {
        method:"get",
        url:`${API.BASE_URL}${API.ORDERID}/?id=${id}`,
        headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${token}`
        },
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }

  export const getQuestionnaires = (campaignId,token) => {
    let headers = {};
    if(campaignId === 3){
       headers = {
        'Content-Type': 'application/json'
     }
    }else{
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
     }
    }
    return new Promise((resolve, reject) => {
      var config = {
        method:"get",
        url:`${API.BASE_URL}${API.CAMPAIGN_QUESTIONNAIRES}?campaignId=${campaignId}`,
        headers: headers
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }

  export const getCampaignUsersCount = () => {
    return new Promise((resolve, reject) => {
      var config = {
        method:"get",
        url:`${API.BASE_URL}${API.CAMPAIGN_USERS_COUNT}`,
        headers: {
           'Content-Type': 'application/json'
        },
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }

  export const getQuizCampaignCount = (campaignId) => {
    return new Promise((resolve, reject) => {
      var config = {
        method:"get",
        url:`${API.BASE_URL}${API.CAMPAIGN_USERS_COUNT}?id=${campaignId}`,
        headers: {
           'Content-Type': 'application/json'
        },
      };
      axios(config)
        .then(function (response) {
          return resolve(response)
        })
        .catch(function (error) {
          return reject(error.message)
        });
  
    })
  }


  // export const LineDataFetch =async (token,id) => {
  //   try {

  // const response = await axios.get(
  //   'https://api.line.me/oauth2/v2.1/verify',
  //   {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': `Bearer ${token}`
  //     },
  //   }

  // if (response.status === 200) {
  //   return response.data;
  // } else {
  //   throw new Error(response.statusText);
  // }

      
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

 