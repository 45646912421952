import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faCircleXmark, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { quizDetails } from "../../Redux/action";
import QuizResultDirect from "../QuizResult/QuizResultDirect";
import { useData } from '../../utils/dataContext';


const Mission = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { updateData, updateScreen } = useData();

    const [isQuestions, setIsQuestions] = useState(true);
    const [brainOpacity, setBrainOpacity] = useState(0.25);
    const [isAnswer, setIsAnswer] = useState(false);
    const [currentQuiz, setCurrentQuiz] = useState({});
    const [isMission, setIsMission] = useState(true);
    const [isResult, setIsResult] = useState(false);
    const [currentSelectedOption, setCurrentSelectedOption] = useState({});
    const [quizData, setQuizData] = useState();


    useEffect(() => {
        setCurrentQuiz(props?.quiz ?? {})
    }, [props.quiz])

    const onSelectOption = (option) => {
        setIsQuestions(false);
        setIsAnswer(true);
        setCurrentSelectedOption(option);
        props.onClickOption(option)
    }

    const onClickNext = () => {
        setIsQuestions(true);
        setIsAnswer(false);
        setBrainOpacity(prevState => {
            let updatedValue = prevState + 0.25;
            return updatedValue <= 1 ? updatedValue : prevState;
        });
        props.onClickNext();
    }

    const onFinish = () => {
        props.setMissionCompleted(prevState => {
            let updatedValue = prevState + 25;
            return updatedValue <= 100 ? updatedValue : prevState;
        });
        let quizData = {
            quizAnswers: props.selectedOptions,
            scorePercentage: props.scorePercentage,
            missionCompleted: props.missionCompleted
        }
        updateData(quizData)
        dispatch(quizDetails(quizData));
        setQuizData(quizData);
        setIsMission(false);
        setIsResult(true);
        updateScreen('quiz-result');
        // navigate('/quiz-result')
    }
    return (
        <div>
            {isMission ?
                (<div className="mission-section">
                    <div className="count-header">
                        <h3>{`${props?.scorePercentage}/100`}</h3>
                        <div className="c-level-up">
                            <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.503 56.1715C17.9005 56.1715 6.83447 45.1055 6.83447 31.503C6.83447 17.9005 17.9005 6.83453 31.503 6.83453C45.1054 6.83453 56.1715 17.9005 56.1715 31.503C56.1715 45.1055 45.1054 56.1715 31.503 56.1715ZM31.503 8.21476C18.6632 8.21476 8.2147 18.6572 8.2147 31.497C8.2147 44.3367 18.6632 54.7852 31.503 54.7852C44.3427 54.7852 54.7912 44.3367 54.7912 31.497C54.7912 18.6572 44.3427 8.21476 31.503 8.21476Z" fill="#EAE255" />
                                <path d="M31.503 63C24.608 63 18.0579 60.8146 12.5673 56.674L12.985 56.1231C18.3546 60.1669 24.7593 62.3099 31.503 62.3099C48.4895 62.3099 62.3099 48.4895 62.3099 31.497C62.3099 14.5045 48.4895 0.690112 31.503 0.690112H31.4849C30.4255 0.690112 29.36 0.744595 28.3128 0.85356L28.2401 0.169501C29.3116 0.0605362 30.4013 0 31.4849 0H31.503C48.8709 0 63.0061 14.1291 63.0061 31.497C63.0061 48.8648 48.8769 63 31.503 63ZM10.4122 54.9003C3.79562 48.9314 0 40.4018 0 31.497C0 16.4416 10.7149 3.4324 25.4797 0.575094L25.6129 1.2531C11.1689 4.04987 0.690112 16.7685 0.690112 31.497C0.690112 40.2021 4.40098 48.544 10.8723 54.3857L10.4122 54.9003Z" fill="#EAE255" />
                            </svg>
                            <h4 className="c-text-inside-circle">{`${props?.missionCompleted}%`}<br></br>Progress</h4>

                        </div>
                    </div>
                    <div className="c-outer-circle">
                        <div className="box">
                            <img src="ring-line.svg"></img>
                        </div>
                    </div>
           
                    <div className="brain content">
                        <img className="brain-center" style={{opacity: brainOpacity,}}
                            src="brain-1.png"></img>
                               <img className="brain-center" style={{opacity: brainOpacity,}}
                            src="brain-1.png"></img>
                    </div>
                    {Object.keys(currentQuiz).length !== 0 && (
                        <div>
                            {isQuestions && (
                                <div>
                                    <div className="d-flex justify-content-center c-content-heading mar-top-50">
                                        <h2>{`Mission ${props.currentMissionNo}`}</h2>
                                    </div>
                                    <div className="d-flex justify-content-center c-content-question mar-top-10">
                                        <p>{currentQuiz?.question}</p>
                                    </div>
                                    {currentQuiz.options.map((option) => (
                                        <div className="answer" key={option.optionId} onClick={() => onSelectOption(option)}>
                                            <div className="e-answer" style={{ backgroundImage: "url('DialogBg.png')" }}>
                                                <p style={{cursor:'pointer'}}>{option.option}</p>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            )}
                            {
                                isAnswer && (
                                    <div>
                                        <div className="d-flex justify-content-center c-content-heading mar-top-50">
                                            <h4>{`Mission ${props.currentMissionNo}`}</h4>
                                        </div>
                                        <div className="answer">
                                            <div className="e-status">
                                                {
                                                    currentSelectedOption?.isCorrect ?
                                                        <p>
                                                            {/* Completed */}
                                                            <span>Completed </span>
                                                            <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#24c24b', marginLeft: '15px' }} /></p> :
                                                        <p>
                                                            <span>Failed</span>
                                                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#ff1f1f", marginLeft: '15px' }} /></p>
                                                }


                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center c-content-heading mar-top-10">
                                            {/* Answer */}
                                            <h2>คำตอบ คือ : {currentQuiz?.answerDetails.answer ?? '-'}</h2>
                                        </div>
                                        <div className="answer">
                                            <div className="e-answer" style={{ backgroundImage: "url('DialogBg.png')" }}>
                                                <p style={{ marginTop: '10px' }}>{currentQuiz?.answerDetails.answerdescription ?? '-'}</p>
                                            </div>
                                        </div>

                                        {props.isLastQuiz ? (
                                            <div className='d-flex justify-content-center align-item-center pad-tb-20'> <Button onClick={onFinish}
                                                style={{ backgroundImage: "url('btn-clip-full.svg')" }} className='start-btn'>
                                                    {/* Finish */}
                                                 ดูผลคะแนน </Button></div>
                                        ) : (
                                            <div className='d-flex justify-content-center align-item-center pad-tb-20'> <Button onClick={onClickNext}
                                                style={{ backgroundImage: "url('btn-clip-full.svg')" }} className='start-btn'>
                                                {/* Next Mission */}
                                                Mission ต่อไป
                                            </Button></div>
                                        )}
                                    </div>
                                )
                            }
                        </div>
                    )}

                </div>)
                 : isResult && <QuizResultDirect 
                 scorePercentage = {props.scorePercentage}
                 quizData = {quizData}
                  />
            }
        </div >
    );
};

export default Mission;

