import './App.css';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { Social } from "./Components/Social/Social";
import Navbar from "./Components/Navbar/navbar";
import { Navfooter } from "./Components/Navbar/footer";
import { useTranslation } from "react-i18next";
import Router from './Route';
import Loader from "../src/Components/Loader/Loader";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from 'react';
import { Particles } from 'react-particles';


function App() {
  const { t, i18n } = useTranslation();
  const [quizScreenName, setQuizScreenName] = useState('start');

  const quizScreen = useSelector(state => state.quizScreen)

  useEffect(() => {
    setQuizScreenName(quizScreen);

  }, [quizScreen])

  return (
    <div>

      <div className="bg-grey ">

        <Particles
        />
        {
          quizScreenName != 'mission' &&
          <Navbar></Navbar>
        }
        <Row className=" d-flex justify-content-center align-items-center">
          <Col md={12} lg={12} xs={12}>
            <Router />
          </Col>
        </Row>

      </div>

    </div>
  );
}

export default App;
