import * as firebase from "firebase/app";
import {getAuth,GoogleAuthProvider,FacebookAuthProvider} from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyCT2NN4zIE9CkSrk2BoEPrddRLoYTQ4Qtc",
//   authDomain: "suntory-f6eeb.firebaseapp.com",
//   projectId: "suntory-f6eeb",
//   storageBucket: "suntory-f6eeb.appspot.com",
//   messagingSenderId: "729282005288",
//   appId: "1:729282005288:web:f4f883c4d05c75444c281d",
//   measurementId: "G-QFSV7ZT2X0"
// };


const firebaseConfig = {
  apiKey: "AIzaSyBCtSG67bWBOesVpdMRTKPRtiEoSkgPABA",
  authDomain: "suntory-test-be2f1.firebaseapp.com",
  projectId: "suntory-test-be2f1",
  storageBucket: "suntory-test-be2f1.appspot.com",
  messagingSenderId: "836269922791",
  appId: "1:836269922791:web:0db8b6502d36085a3c8542"
};


const firebaseApp = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp)
const provider = new GoogleAuthProvider();
const FbProvider = new FacebookAuthProvider();

export { auth, provider,FbProvider };