import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import "./i18n.js"
import './assets/fonts/DB-Helvethaica.ttf';
import './assets/fonts/Armstrong_Oblique.ttf'
import { LiffProvider } from 'react-liff';
import { API } from './Constants/Constants';
import './theme.css';
import { DataProvider } from './utils/dataContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
const liffId = API.VITE_LIFF_ID
root.render(
  <React.StrictMode>
     <Provider store={store}>
    <PersistGate  persistor={persistor}>
    <LiffProvider liffId={liffId} >
      <DataProvider>
      <App />
      </DataProvider>
    </LiffProvider>
    </PersistGate>
    </Provider>
  </React.StrictMode>
);
